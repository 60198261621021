//@ts-nocheck
import geoViewport from "@mapbox/geo-viewport";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CompassCalibrationIcon from '@mui/icons-material/CompassCalibration';
import DeleteIcon from '@mui/icons-material/Delete';
import FeedIcon from '@mui/icons-material/Feed';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import { IconButton, Stack, Tooltip } from "@mui/material";
import KeplerGl from "kepler.gl";
import { ActionTypes, addDataToMap, resetMapConfig } from "kepler.gl/actions";
import { processGeojson } from 'kepler.gl/processors';
import keplerGlReducer from "kepler.gl/reducers";
import KeplerGlSchema from 'kepler.gl/schemas';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useCallback, useEffect, useState } from "react";
import { taskMiddleware } from "react-palm/tasks";
import { Provider, connect, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { applyMiddleware, combineReducers, createStore } from "redux";
import { handleActions } from "redux-actions";
import AccessControl, { UserRole } from "../components/AccessControl";
import CustomLegend from "../components/CustomLegend";
import Loading from "../components/Loading";
import Logout from "../components/Logout";
import RFModal from '../components/RFModal';
import SaveButton from "../components/SaveChanges";
import SavedMaps from '../components/SavedMaps';
import { ToggleButton } from "../components/ToggleButton";
import UserManagement from "../components/UserManagement";
import { MapTypes, calculateCenter, convertBboxToCoordinates, generateCoordinates, getUserToken } from '../components/utility/Map';
import { AlertType, useNotification } from '../context/Notifications';
import { useMap } from "../contexts/Map";
import useDebounce from '../hooks/debounce';
import { fetchMapFromS3, removeProperties } from '../utility';

const WAIT_TIME = 2000; // wait to seconds before issuing api request
const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
const URL = process.env?.REACT_APP_URL || 'http://localhost'

interface IMapData {
    lonMax: number,
    lonMin: number,
    latMin: number,
    latMax: number,
    zoom: number
}

export interface IClientData {
  client_data_id: string,
  name: string,
  description: string,
  user_id: string,
  username: string,
  mapState?: any, 
  visualState?: any, 
  created_at: string,
  update_at: string, // @TODO: Update for actual timestamp dates
  access_list: string[]
}

export interface Transmitter {
  lat:number,
  lon:number,
  alt:number,
  frq:number,
  txw:number,
  rfPowerUnit:string,
  bwi:number,
  antennaMode:string,
  ant:number,
  pol:string,
  txg:number,
  azi:number,
  tlt:number,
  hbw:number,
  vbw:number,
  fbr:number,
  txl:number,
}

export interface RFFormData {
        site: string,
        network: string,
        lat:number,
        lon:number,
        alt:number,
        frq:number,
        txw:number,
        rfPowerUnit:string,
        bwi:number,
        res:number,
        col:string,
        rad:number,
        engine:number,
        lineType:number,
        lineLength:'',
        connectors:'',
        antennaMode:string,
        ant:number,
        pol:string,
        txg:number,
        azi:number,
        tlt:number,
        rla:number,
        rlo:number,
        rxh:number,
        rxg:number,
        out:number,
        rxs:number,
        noise:'',
        pm:number,
        rel:number,
        pe:number,
        ked:number,
        clt:string,
        elevation:number,
        landcover:number,
        buildings:number,
        obstacles:number,
        transmitters:Transmitter[]
      }


const Map = ({ mapState }: IMapData) => {
    const [ activeClientDataId, setActiveClientId ] = React.useState(null);
    const [ hasClientData, setHasClientData ] = React.useState(false);
    const [ isLoading, setIsLoading ] = React.useState(false);
    const [ mapRef, setMapRef ] = React.useState(null);
    const debouncedValue = useDebounce(mapState, WAIT_TIME);
    const [advancedMap, setAdvancedMap] = React.useState(process.env?.REACT_APP_DEFAULT_MAP_TO_ADVANCED?.toLowerCase() ==='true');
    const [ selectedMap, setSelectedMap ] = React.useState<IClientData>(null);
    const [legndsData, setLegendsData] = useState([])
    const [rfOpen, setRfOpen] = React.useState(false);
    const handleRfOpen = () => setRfOpen(true);
    const [rfColor, setRfColor] = useState('');
    const handleRfClose = () => setRfOpen(false);
    const { rfMapData, transmitterCoordinates } = useMap();
    const [ showRFLayer, setShowRFLayer ] = React.useState(false);
    const [rfValues, setRfValues] = useState<RFFormData>({})
    const [transmitters, setTransmitters] = useState<Transmitter>([])

    const [ dataLoaded, setDataLoaded ] = useState(false);
    const dispatch = useDispatch();
    const { showAlert } = useNotification();
    const navigate = useNavigate();

    useEffect(()=>{
      setRfValues({
        site: "A1",
        network: "Testing",
        res:10,
        col:'RAINBOW.dBm',
        rad:5,
        engine:2,
        lineType:1,
        lineLength:'',
        connectors:'',
        rla:0,
        rlo:0,
        rxh:1,
        rxg:3,
        out:2,
        rxs:-100,
        noise:'',
        pm:1,
        rel:95,
        pe:2,
        ked:1,
        clt:'Minimal.clt',
        elevation:1,
        landcover:0,
        buildings:0,
        obstacles:0,
        transmitters:transmitters
      })
    },[transmitters])

    useEffect(()=>{
      setTransmitters([
        {
          lat:47.61866,
          lon:-122.31834,
          alt:2,
          frq:868,
          txw:0.1,
          rfPowerUnit:'W',
          bwi:1,
          antennaMode:'template',
          ant:0,
          pol:'h',
          txg:2.15,
          azi:1,
          tlt:10,
          hbw:120,
          vbw:120,
          fbr:1.95,
          txl:1
        }
      ])
    },[])

    useEffect(() => {
        if(!advancedMap){
            let mapData = debouncedValue?.app?.mapData
            
            if(mapRef && mapData) {
                const coordinates = generateCoordinates(
                  mapData.lonMax,
                  mapData?.lonMin,
                  mapData?.latMax,
                  mapData?.latMin
                );
                const PATH = `geometries/raster_multilayer`;
                getUserToken().then(async (token) => {
                  let response = await fetch(
                    `${URL}/geometries/raster_layer_config?min_lon=${mapData?.lonMin}&max_lon=${mapData?.lonMax}&min_lat=${mapData?.latMin}&max_lat=${mapData?.latMax}`,
                    {
                      method: "GET",
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );

                  const layer_configs = await response.json();
                  if (layer_configs.length > 0) {
                    const layer_ids = new Set(
                      layer_configs.map((config) => config.layer_id)
                    );
                    let configs = [];
                    layer_ids.forEach((id) => {
                      const layers = layer_configs.filter(
                        (c) => c.layer_id === id
                      );
                      if (layers.length > 1) {
                        const min_to_q25 = layers.find(
                          (x) => x.stat === "min_to_q25"
                        );
                        const q25_to_q50 = layers.find(
                          (x) => x.stat === "q25_to_q50"
                        );
                        const q50_to_q75 = layers.find(
                          (x) => x.stat === "q50_to_q75"
                        );
                        const q75_to_max = layers.find(
                          (x) => x.stat === "q75_to_max"
                        );

                        configs.push({
                          layer_id: id.substr(0, 4),
                          min_to_q25_value: `${min_to_q25["lower_bound"].toFixed(0)}-${min_to_q25["upper_bound"].toFixed(0)}`,
                          q25_to_q50_value: `${q25_to_q50["lower_bound"].toFixed(0)}-${q25_to_q50["upper_bound"].toFixed(0)}`,
                          q50_to_q75_value: `${q50_to_q75["lower_bound"].toFixed(0)}-${q50_to_q75["upper_bound"].toFixed(0)}`,
                          q75_to_max_value: `${q75_to_max["lower_bound"].toFixed(0)}-${q75_to_max["upper_bound"].toFixed(0)}`,
                          min_to_q25_colour: min_to_q25["layer_colour"],
                          q25_to_q50_colour: q25_to_q50["layer_colour"],
                          q50_to_q75_colour: q50_to_q75["layer_colour"],
                          q75_to_max_colour: q75_to_max["layer_colour"],
                        });
                      } else {
                        configs.push({
                          layer_id: id.substr(0, 4),
                          layer_colour: layers[0]?.layer_colour,
                        });
                      }
                    });
                    
                    setLegendsData(configs);
                  }
                });
                
               
               renderRasher(mapRef, `${URL}/${PATH}?min_lon=${mapData?.lonMin}&max_lon=${mapData?.lonMax}&min_lat=${mapData?.latMin}&max_lat=${mapData?.latMax}&height=${mapData?.height}&width=${mapData?.width}`, coordinates)
            }
        }else{
          // setIsLoading(false)
        }

    }, [debouncedValue?.app?.mapData, advancedMap])

    const passValuesToReducer = () => ({
        type: "MAP_VALUES",
        payload: {
            setIsLoading,
            advancedMap
        },
      })

    useEffect(()=>{
        dispatch(passValuesToReducer())
    },[advancedMap])

    const loadRaster = async (map, imageUrl, coordinates) =>{
        if(map.loaded()) {
            if(map.getSource('image-source')) {
                await map.removeLayer('raster-layer');
                await map.removeSource('image-source');
                await map.addSource('image-source', {
                    type: 'image',
                    url: imageUrl,
                    coordinates: coordinates
                });
            } else {
                await map.addSource('image-source', {
                    type: 'image',
                    url: imageUrl,
                    coordinates: coordinates
                });
            }

            if(map.getLayer('raster-layer')) {
                // update layer
                await map.removeLayer('raster-layer');
                await map.addLayer({
                    id: 'raster-layer',
                    type: 'raster',
                    source: 'image-source',
                    paint: {
                        'raster-fade-duration': 0
                    }
                });
            } else {
                await map.addLayer({
                    id: 'raster-layer',
                    type: 'raster',
                    source: 'image-source',
                    paint: {
                        'raster-fade-duration': 0
                    }
                });
            }
        }
    }
    

    const overlayRasher = async (map: any, image: string, coordinates: number[][]) => {
        const token = await getUserToken();
        let response = await fetch(image, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}` //TODO: Please can we make this more generic for all calls
            }
        });

        let blob = await response?.blob();
        
        let objectURL = window?.URL?.createObjectURL(blob); 
       
        await loadRaster(map, objectURL, coordinates);
    }

    const renderRasher = async (mapRef: any, image: string, coordinates: number[][]) => {
        setIsLoading(true)
        await overlayRasher(mapRef, image, coordinates);
        setIsLoading(false)

    }

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => { 
      setAdvancedMap(event.target.checked);    
        if(mapRef.getLayer('raster-layer')) {
            await mapRef.removeLayer('raster-layer');
        }
        if(mapRef.getSource('image-source')) {
            await mapRef.removeSource('image-source');
        }
    };

    const clearLayer = async() => {
      if(mapRef.getLayer('raster-layer')) {
        await mapRef.removeLayer('raster-layer');
      }
      if(mapRef.getSource('image-source')) {
          await mapRef.removeSource('image-source');
      }
    }

    useEffect(()=>{
        let readOnlyStatus = true
        let geocoderStatus = true
        if(advancedMap){
            readOnlyStatus = false
            geocoderStatus = false
        }
        dispatch(
            addDataToMap({
                options : {
                    readOnly : readOnlyStatus
                },
                config :{
                    visState :{
                        interactionConfig: {
                            geocoder: {
                                enabled: geocoderStatus,
                            }
                        },
                    },
                    mapStyle:{
                      styleType: MapTypes.Satellite, //@TODO: Pick default map state
                    }
                },               

            })
        )
    }, [advancedMap]);

    const uploadJsonToS3 = async (preSignedUrl, jsonData) => {
      const blob = new Blob([JSON.stringify(jsonData)], { type: 'application/json' });

      try {
          const response = await fetch(preSignedUrl, {
            method: 'PUT',
            mode: 'cors',
            body: blob,
            headers: {
                'Content-Type': 'application/json'
            }
          });

          
          return response;
      } catch (error) {
          console.error('Error uploading file:', error);
          throw error;
      }
    };


    const postData = async (data) => {
       
        const bodyData =  {  mapState: data?.mapState, visState: data?.visState, ...data };
        
        try {
            const token = await getUserToken();
            const response = await fetch(`${URL}/data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(removeProperties(bodyData))
            });
            
            if (!response.ok) {
              showAlert(AlertType.Error , "Error saving client data")
            } else {
              const url = await response.json();
              await uploadJsonToS3(url?.upload_url, { mapState: data?.mapState, visState: data?.visState })
            }
        } catch (error) {
            showAlert(AlertType.Error , "Error saving client data")
        }
    }



    const putData = async (data) => {
        const bodyData = {  mapState: data?.mapState, visState: data?.visState, ...data };
       
        try {
            const token = await getUserToken();
            const response = await fetch(`${URL}/data/${data?.client_data_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(removeProperties(bodyData))
            });
           
            if (!response.ok) {
                showAlert(AlertType.Error , "Error saving client data")
            } else {
              const url = await response.json();
                await uploadJsonToS3(url?.upload_url, { mapState: data?.mapState, visState: data?.visState })
              }
        } catch (error) {
            showAlert(AlertType.Error , "Error saving client data")
        }
    }
   

    const handleSave = async (name: string, description: string, overwrite: boolean = false, access_list = []) => {
        const configToSave = KeplerGlSchema.getConfigToSave(store.getState()?.keplerGl?.map)
        const dataToSave = KeplerGlSchema.getDatasetToSave(store.getState()?.keplerGl?.map);
      
        if(configToSave && dataToSave?.length !== 0) {
            try {
                setIsLoading(true);
                if(overwrite) {
                    await putData({
                        mapState: dataToSave, 
                        visState: configToSave,
                        name,
                        description,
                        client_data_id: selectedMap?.client_data_id,
                        access_list
                    })
                } else {
                    await postData({
                        mapState: dataToSave, 
                        visState: configToSave,
                        name,
                        description,
                        access_list
                    });
                }
                
                showAlert(AlertType.Success, "Saved Client Data");
            } catch (e) {
                showAlert(AlertType.Error , "Error saving client data")
            } finally {
                setIsLoading(false);
            }
        } else {
            showAlert(AlertType.Error, "No data to save. Please upload you're data first!")
        }
    }

    const getMap = async (id: string) => {
      const token = await getUserToken();
      try {
        const data = await fetch(`${URL}/client_map/${id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
  
        const state = await data.json();
        const res = await fetchMapFromS3(state?.url);
        
        const mapToLoad = KeplerGlSchema.load(res.mapState, res?.visualState || res?.visState);

        // Switch to advance mode view
        setAdvancedMap(true);
        
        await dispatch(addDataToMap(mapToLoad));   
        showAlert(AlertType.Success, "Loaded data successfully")

      } catch(error) {
        // @TODO: P
        showAlert(AlertType.Error, "Error fetching map")
      }
     
    }

    const fetchData = async(id: string) => {
        // dispatch(resetMapConfig());
        try {
            setIsLoading(true)
            const token = await getUserToken();
            const data = await fetch(`${URL}/client_data/${id}`, {
                method: 'GET',
                headers: {
                'Authorization': `Bearer ${token}`
                }
            });
            // Check status 
            if(data.status === 404) {
                return null;
            }
            const state = await data.json();
            if(state?.client_data_id) {
                setActiveClientId(state?.client_data_id);
                setSelectedMap(state);
            }
            
            await getMap(state?.client_data_id);
        } catch(error) {
            showAlert(AlertType.Error, "Error fetching data")
        } finally {
            setDataLoaded(true);
            setIsLoading(false);
        }
    }

    const deleteSaveData = async (client_id: string) => {
        setIsLoading(true);
        try {
            const token = await getUserToken();
            const response = await fetch(`${URL}/client_data/${client_id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
              });
            
            dispatch(resetMapConfig())
              if (response.status === 204) {
                showAlert(AlertType.Success, "Successfully deleted client data")
              } else {
                const errorData = await response.json();
                showAlert(AlertType.Error, errorData?.detail || "Error deleting data")
              } 
        } catch (error) {
            console.log(error);
            showAlert(AlertType.Error, "Error deleting data");
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        // process.env?.REACT_APP_DEFAULT_MAP_TO_ADVANCED?.toLowerCase() ==='true' && fetchData();
    }, []);

    const RFDataTransfer = useCallback(() => {
      setIsLoading(true);
      if(mapRef && rfMapData) {
        loadRaster(mapRef, rfMapData.image_url, convertBboxToCoordinates(rfMapData.bounds));
        const processedData = processGeojson({
          type: 'FeatureCollection', 
          features: transmitterCoordinates.map(((item, i) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [item?.longitude, item?.latitude],
            },
            properties: {
              ...item,
              id: `transmitter-${i}`,
            },
          }
        }))});
        const data = {
          info: {
            id: 'transmitters',
            label: 'Transmitters'
          },
          data: processedData,
        };
        
        dispatch(
          addDataToMap({    
              datasets: data,  
              options: {
                centerMap: false
              },              
              config :{                        
                mapState: {
                  "latitude": calculateCenter(convertBboxToCoordinates(rfMapData.bounds)).latitude,
                  "longitude": calculateCenter(convertBboxToCoordinates(rfMapData.bounds)).longitude,
                  "zoom": 13 // @TODO: Match zoom to RF
                },                       
              }
              
          })
        )
      } 

      setIsLoading(false);

    }, [mapRef, rfMapData, dataLoaded])

    return (
      <div style={{ backgroundColor: "#29323C", position: "fixed" }}>
        {isLoading && <Loading open={isLoading} />}
        <KeplerGl
          id="map"
          mapboxApiAccessToken={TOKEN}
          width={window.innerWidth}
          height={window.innerHeight}
          hideSidePanel={false}
          config={{}}
          getMapboxRef={(ref) => {
            const map = ref?.getMap();
            setMapRef(map);
          
          }}
        />
        
        {!advancedMap && (
          <div
            style={{
              position: "absolute",
              right: "13px",
              zIndex: 1000,
              top: "104px",
            }}
          >
            <CustomLegend legendsData={legndsData} />
          </div>
        )}


        <Stack style={{position: 'absolute', bottom: 130, right: 20 }}>
          {/* User Managment */}
          <AccessControl onlyShowTo={[UserRole.ADMIN]}>
            <UserManagement />
          </AccessControl>
          {/* RF layer button */}
          <Tooltip title="Toggle RF Layer">
              <IconButton
                onClick={() => {
                  if(!showRFLayer) {
                    if(!rfMapData) {
                      showAlert(AlertType.Error, "No RF Data to show")
                    } else {
                      RFDataTransfer();
                    }
                    
                  } else {
                    clearLayer();
                  }

                  setShowRFLayer(!showRFLayer);
                }}
                style={buttonStyle}
              >
                <CompassCalibrationIcon 
                  style={{ fontSize: "16px" }} 
                />
              </IconButton>
            </Tooltip>

            {/* RF form button */}
            <Tooltip title="RF Form">
            <IconButton
              onClick={handleRfOpen}
              style={buttonStyle}
            >
              <FeedIcon style={{ fontSize: "16px" }} />
            </IconButton>
          </Tooltip>

          {/* Saved maps button */}
          <SavedMaps activeMapId={selectedMap?.client_data_id} handleDelete={deleteSaveData} handleLoad={fetchData} clearMap={() => dispatch(resetMapConfig())} />

          {/* Navigate to wireless view button */}
          <Tooltip title="Navigate to wireless view">
            <IconButton
              onClick={() => navigate("/pin")}
              style={buttonStyle}
            >
              <WifiTetheringIcon style={{ fontSize: "16px" }} />
            </IconButton>
          </Tooltip>

          {/* Logout button */}
          <Logout />

          {/* Load / delete saved data buttons */}
          {hasClientData && (
              <div>
                  <Tooltip title="Load Save Data">
                      <IconButton 
                          style={buttonStyle}
                          onClick={fetchData}>
                          <CloudDownloadIcon style={{ fontSize: '16px'}} />
                      </IconButton>
                  </Tooltip>
                  {
                      activeClientDataId && (
                          <Tooltip title="Delete Saved Data">
                              <IconButton 
                                  style={{...buttonStyle, marginLeft:"10px"}}
                                  onClick={deleteSaveData}>
                                  <DeleteIcon style={{ fontSize: '16px'}}  />
                              </IconButton>
                          </Tooltip>
                      )
                  }
              </div>
          )
        }

        </Stack>       

        <RFModal
          open={rfOpen}
          handleClose={handleRfClose}
          setRfColor={setRfColor}
          rfValues = {rfValues}
          setRfValues = {setRfValues}
          setTransmitters = {setTransmitters}
          onSubmit={async (payload) => {
            if (mapRef) {
                dispatch(
                    addDataToMap({                    
                        config :{                        
                            mapState: {
                                "latitude": payload?.transmitters?.[0]?.lat,
                                "longitude": payload?.transmitters?.[0]?.lon,
                                "zoom": 12
                              }                        
                        }    
                    })
                )
              const token = await getUserToken();
              const response = await fetch(`${URL}/geometries/multi_site_radio_propagation`, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}`
                  },
                  body: JSON.stringify(payload)
              });

              const body = await response.json();
              const [maxLat, maxLon, minLat, minLon] = body.bounds;

              const coordinates = generateCoordinates(
                maxLon,
                minLon,
                maxLat,
                minLat
              );

              if (mapRef.loaded()) {
                const rasterId = `raster-layer-${lat}-${lon}`;
                const imageId = `image-source-${lat}-${lon}`;
                if (mapRef.getLayer(rasterId)) {
                  await mapRef.removeLayer(rasterId);
                }
                if (mapRef.getSource(imageId)) {
                  await mapRef.removeSource(imageId);
                }
                await mapRef.addSource(imageId, {
                  type: "image",
                  url: body.image_url,
                  coordinates: coordinates,
                });

                await mapRef.addLayer({
                  id: rasterId,
                  type: "raster",
                  source: `image-source-${lat}-${lon}`,
                  paint: {
                    "raster-fade-duration": 0,
                  },
                });
              }
              handleRfClose();
            }
          }}
        />

        {process.env?.REACT_APP_DISPLAY_SIMPLE_ADVANCED_TOGGLE?.toLowerCase() ===
          "true" && (
          <ToggleButton handleChange={handleChange} checked={advancedMap} />
        )}

        {advancedMap &&
          <SaveButton
              selectedMap={selectedMap}
              handleSave={handleSave}
          />
        }

        {rfColor && (
          <div style={{ position: "absolute", bottom: 180, left: 20 }}>
            <img
              src={`https://api.cloudrf.com/API/colours/new/${rfColor}.key.png`}
            ></img>
          </div>
        )}
      </div>


    );
};

export const buttonStyle = {
  backgroundColor: "#3a414c",
  color: "#A0A7B4",
  borderRadius: 0,
  marginBottom: 10,
  width:"32px",
  alignSelf:"flex-end"
}

let timerId;

const appReducer = handleActions(
    {
        "MAP_VALUES": (state, action) => {
            return {
                ...state,
                mval: action.payload
            }
        },
        // listen on kepler.gl map update action to store a copy of viewport in app state
        [ActionTypes.UPDATE_MAP]: (state, action) => {
            const mapState = action.payload.payload;
            const viewport = [mapState.longitude, mapState.latitude];
            const zoom = mapState.zoom;
            const dimensions = [mapState.width, mapState.height];
            const tileSize = 512;
            let [lonMin, latMin, lonMax, latMax] = geoViewport.bounds(
                viewport,
                zoom,
                dimensions,
                tileSize
            );
            
                if(timerId){
                    clearTimeout(timerId)
                }
                timerId = setTimeout(async() => {
                    if(lonMin){
                        if(!state?.mval.advancedMap) {
                          state.mval?.setIsLoading(true)
                        }
                    }
                }, 500)
            

            return {
                ...state,
                mapData: {
                    lonMax,
                    lonMin,
                    latMin,
                    latMax,
                    width: mapState.width,
                    height: mapState.height,
                    zoom,
                    viewport
                },
                viewport: action.payload,
            };
        },
    },
    {}
);

const mapStateToProps = state => ({
    mapState: state
});

const ConnectedMap = connect(mapStateToProps)(Map);

const customizedKeplerGlReducer = keplerGlReducer.initialState({
    uiState: {
        currentModal: null
    },
    mapStyle:{
      styleType: MapTypes.Satellite,
    }
});

const reducers = combineReducers({
    app: appReducer,
    keplerGl: customizedKeplerGlReducer
});

const store = createStore(reducers, {}, applyMiddleware(taskMiddleware));

const ProvidedMap = () => {
    return (
        <Provider store={store}>
            <ConnectedMap />
        </Provider>
    );
};

export default ProvidedMap;
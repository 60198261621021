import { Auth } from "aws-amplify";
import React, { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AlertType, useNotification } from "../context/Notifications";

const AuthContext = createContext<any | undefined>(undefined);

const AuthProvider = ({ children }: any) => {
  const [user, setUser] = React.useState(null);
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(null);
  const { showAlert } = useNotification();

  React.useEffect(() => {
    checkAuthState();
  }, []);

  const checkAuthState = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
      setIsAuthenticated(true);
    } catch {
      setIsAuthenticated(false);
    }
  };

  React.useEffect(() => {
    if (isAuthenticated === true) {
      showAlert(AlertType.Success, "Successfully logged in");
    }
    if (isAuthenticated === false) {
      showAlert(AlertType.Error, "Failed to logged in");
      navigate("/");
    }
  }, [isAuthenticated, navigate, user]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Or any other loading state
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth can only used inside AuthProvider");
  }

  return context;
};

export { AuthProvider, useAuth };

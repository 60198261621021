import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/Auth";

interface RouteGuardProps {
  children: JSX.Element;
}

const RouteGuard = ({ children }: RouteGuardProps) => {
  const { user, isAuthenticated } = useAuth();
  if (isAuthenticated === null) {
    return <div> Loading..</div>;
  }
  if (!user) {
    return <Navigate to="/" />;
  }

  return children;
};

export default RouteGuard;

import React, { useState } from "react";
import "./Banner.css";

const Banner = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div className="notification-alert">
        <p>
          Notice: Account details will be changed on 30 October. If you are not using your own account or data needs to
          be moved to your user's account please reach out to ngumede@sandtech.com detailing the data required to move
          to your user account.
        </p>
        <button onClick={handleClose}>Close</button>
      </div>
    </>
  );
};

export default Banner;

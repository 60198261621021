export const truncateString = (str: string, maxLength: number): string => {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.substring(0, maxLength) + "...";
  }
}

export const fetchMapFromS3 = async (url: string) => {
try {
    const response = await fetch(url);
    
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
} catch (error) {
    console.error('Failed to fetch JSON from S3:', error);
    throw error;
}
};

export const removeProperties = (obj: { [x: string]: any; }, propertiesToRemove = ['mapState', 'visState', 'visualState']) => {
propertiesToRemove.forEach(prop => {
    if (prop in obj) {
        delete obj[prop];
    }
});

return obj;
};

//@ts-nocheck
import AddLocationIcon from "@mui/icons-material/AddLocation";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import ClearIcon from "@mui/icons-material/Clear";
import CompassCalibrationIcon from "@mui/icons-material/CompassCalibration";
import MapIcon from "@mui/icons-material/Map";
import { Box, IconButton, Tooltip } from "@mui/material";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect } from "react";
import type { MarkerDragEvent } from "react-map-gl";
import Map, { Marker } from "react-map-gl";
import { useNavigate } from "react-router-dom";
import GeocoderControl from "../components/GeoEncoder";
import Logout from "../components/Logout";
import RFLegend from "../components/RFLegend";
import { ToggleButton } from "../components/ToggleButton";
import { overlayRasher } from "../components/utility/Map";
import { AlertType, useNotification } from "../context/Notifications";
import { useMap } from "../contexts/Map";
import Pin, { DEFAULT_ALTITUDE, DEFAULT_AZI, DEFAULT_FREQUENCY } from "./Pin";
import Banner from "../components/NotificationBanner/NotificationBanner";
import { useAuth, AuthProvider } from "../contexts/Auth";
/*
  The following workaround is required to run react-map-gl on production 
  https://github.com/visgl/react-map-gl/issues/1266 
*/

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const DEFAULT_LATITUDE = 47.608013;
const DEFAULT_LONGITUDE = -122.335167;
const DEFAULT_ZOOM_LEVEL = 12;

const initialViewState = {
  latitude: DEFAULT_LATITUDE,
  longitude: DEFAULT_LONGITUDE,
  zoom: DEFAULT_ZOOM_LEVEL,
};

const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const DEFAULT_RESOLUTION = 10;
const DEFAULT_RADIUS = 5;
const DEFAULT_TILT = 0;
const DEFAULT_PROPOGATION_MODEL = 1;

const PinMap = () => {
  const { user } = useAuth();

  console.log(user);
  const [advancedMap, setAdvancedMap] = React.useState(false);
  const { rfMapData, transmitterCoordinates } = useMap();
  const navigate = useNavigate();
  const { showAlert } = useNotification();
  // @TODO: Move these into a context
  const [resolution, setResolution] = React.useState(DEFAULT_RESOLUTION);
  const [radius, setRadius] = React.useState(DEFAULT_RADIUS);
  const [tilt, setTilt] = React.useState(DEFAULT_TILT);
  const [progationModel, setPropogationModel] = React.useState(DEFAULT_PROPOGATION_MODEL);
  // End
  const [showRFLayer, setShowRFLayer] = React.useState(false);
  const [mapRef, setMapRef] = React.useState<any>(null);
  const [isPinned, setIsPinned] = React.useState(false);
  const [isMoving, setIsMoving] = React.useState(false);
  const [coordinates, setCoordinates] = React.useState([
    {
      id: 0,
      lat: DEFAULT_LATITUDE,
      lng: DEFAULT_LONGITUDE,
    },
  ]);

  const [markers, setMarker] = React.useState([
    {
      id: 0,
      latitude: DEFAULT_LATITUDE,
      longitude: DEFAULT_LONGITUDE,
      frequency: DEFAULT_FREQUENCY,
      altitude: DEFAULT_ALTITUDE,
      azi: DEFAULT_AZI,
    },
  ]);

  const onMarkerDragStart = React.useCallback((event: MarkerDragEvent) => {
    setIsPinned(false);
  }, []);

  const onMarkerDrag = React.useCallback(
    (event: MarkerDragEvent, id: string | number) => {
      setMarker(
        markers.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              longitude: event.lngLat.lng,
              latitude: event.lngLat.lat,
            };
          }
          return item;
        })
      );

      setCoordinates(
        coordinates.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              lng: event.lngLat.lng,
              lat: event.lngLat.lat,
            };
          }

          return item;
        })
      );
    },
    [markers, coordinates]
  );

  const onMarkerDragEnd = React.useCallback(
    (event: MarkerDragEvent, id) => {
      setCoordinates(
        coordinates.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              lng: event.lngLat.lng,
              lat: event.lngLat.lat,
            };
          }

          return item;
        })
      );
    },
    [markers, coordinates]
  );

  const updateMarkerLocation = (lat: number, long: number, id: string | number) => {
    try {
      setMarker(
        markers.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              longitude: long,
              latitude: lat,
            };
          }
          return item;
        })
      );

      setCoordinates(
        coordinates.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              lng: long,
              lat: lat,
            };
          }

          return item;
        })
      );

      if (mapRef) {
        mapRef.flyTo({
          center: [long, lat],
          zoom: 15,
        });
      }
    } catch (error) {
      showAlert(AlertType.Error, "Error location");
    }
  };

  const updateFrequency = (id: string | number, frequency: string | number) => {
    setMarker(
      markers.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            frequency: parseFloat(frequency),
          };
        }

        return item;
      })
    );
  };

  const updateAltitude = (id: string | number, altitude: string | number) => {
    setMarker(
      markers.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            altitude: parseFloat(altitude),
          };
        }

        return item;
      })
    );
  };

  const updateAzi = (id: string | number, azi: string | number) => {
    setMarker(
      markers.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            azi: parseFloat(azi),
          };
        }

        return item;
      })
    );
  };

  const centerMarker = () => {
    const bounds = mapRef.getBounds();
    const center = bounds.getCenter();

    setMarker(
      markers.map((item, i) => {
        return {
          id: item.id,
          longitude: center?.lng + i * 10,
          latitude: center?.lat + i * 10,
        };
      })
    );

    setCoordinates(
      coordinates.map((item, i) => {
        return {
          id: item.id,
          lng: center?.lng + i * 10,
          lat: center?.lat + i * 10,
        };
      })
    );

    setIsPinned(false);
  };

  const addPin = () => {
    const bounds = mapRef.getBounds();
    const center = bounds.getCenter();

    setMarker([
      ...markers,
      {
        id: markers.length,
        longitude: center?.lng,
        latitude: center?.lat,
        frequency: DEFAULT_FREQUENCY,
        altitude: DEFAULT_ALTITUDE,
        azi: DEFAULT_AZI,
      },
    ]);

    setCoordinates([
      ...coordinates,
      {
        id: coordinates.length,
        lng: center?.lng,
        lat: center?.lat,
      },
    ]);
  };

  const removeMarker = (id) => {
    setMarker(markers.filter((item) => item.id !== id));
    setCoordinates(coordinates.filter((item) => item.id !== id));
  };

  const clearAll = () => {
    setMarker([]);
    setCoordinates([]);
  };

  useEffect(() => {
    if (advancedMap) {
      navigate("/map");
    }
  }, [advancedMap]);

  const clearLayer = async () => {
    if (mapRef.getLayer("raster-layer")) {
      await mapRef.removeLayer("raster-layer");
    }
    if (mapRef.getSource("image-source")) {
      await mapRef.removeSource("image-source");
    }
  };

  const RFDataTransfer = () => {
    clearAll();
    if (mapRef && rfMapData) {
      overlayRasher(mapRef, rfMapData?.image_url, rfMapData?.bounds);

      setMarker(transmitterCoordinates);
    }
  };

  return (
    <>
      {user.attributes.email === "chester@explore.ai" && (
        <div>
          <Banner />
        </div>
      )}
      <Map
        initialViewState={initialViewState}
        mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
        mapboxAccessToken={TOKEN}
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
        }}
        onMoveStart={() => setIsMoving(true)}
        onMoveEnd={() => setIsMoving(false)}
        onMove={() => {
          // checkPinVisibility();
        }}
        onLoad={(evt) => {
          setMapRef(evt?.target);
        }}
      >
        {isPinned ? (
          <Box sx={{ position: "fixed", bottom: 180, right: 20, borderRadius: 0 }}>
            <Tooltip title="Click to center the marker">
              <IconButton onClick={() => centerMarker()} style={{ backgroundColor: "#3a414c", color: "#A0A7B4" }}>
                <AddLocationIcon sx={{ fontSize: `20px` }} />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <>
            {markers.map((marker, i) => (
              <>
                <Marker
                  key={i}
                  longitude={marker.longitude}
                  latitude={marker.latitude}
                  anchor="bottom"
                  draggable
                  onDragStart={(e) => onMarkerDragStart(e)}
                  onDrag={(e) => onMarkerDrag(e, marker.id)}
                  onDragEnd={(e) => onMarkerDragEnd(e, marker.id)}
                >
                  <Pin
                    updateAltitude={updateAltitude}
                    updateFrequency={updateFrequency}
                    removeMarker={removeMarker}
                    allCoordinates={markers}
                    key={i}
                    id={marker.id}
                    isMoving={isMoving}
                    updateMarkerLocation={updateMarkerLocation}
                    coordinates={{
                      lat: marker.latitude,
                      lng: marker.longitude,
                    }}
                    map={mapRef}
                    updateAzi={updateAzi}
                    {...{
                      resolution,
                      setResolution,
                      radius,
                      setRadius,
                      tilt,
                      setTilt,
                      progationModel,
                      setPropogationModel,
                    }}
                  />
                </Marker>
              </>
            ))}
          </>
        )}
        <GeocoderControl updateMarkerLocation={updateMarkerLocation} mapboxAccessToken={TOKEN} position="top-right" />
      </Map>
      <Box display={"flex"} flexDirection={"column"} sx={{ position: "fixed", bottom: 120, right: 20 }}>
        <Tooltip title="Toggle RF Layer">
          <IconButton
            onClick={() => {
              if (showRFLayer) {
                RFDataTransfer();
              } else {
                clearLayer();
              }

              setShowRFLayer(!showRFLayer);
            }}
            style={{
              backgroundColor: "#3a414c",
              color: "#A0A7B4",
              borderRadius: 0,
              marginBottom: 10,
            }}
          >
            <CompassCalibrationIcon style={{ fontSize: "16px" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Clear all pins">
          <IconButton
            onClick={() => clearAll()}
            style={{
              backgroundColor: "#3a414c",
              color: "#A0A7B4",
              borderRadius: 0,
              marginBottom: 10,
            }}
          >
            <ClearIcon style={{ fontSize: "16px" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Add new pin">
          <IconButton
            onClick={() => addPin()}
            style={{
              backgroundColor: "#3a414c",
              color: "#A0A7B4",
              borderRadius: 0,
              marginBottom: 10,
            }}
          >
            <AddLocationAltIcon style={{ fontSize: "16px" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Navigate to fibre view">
          <IconButton
            onClick={() => navigate("/map")}
            style={{
              backgroundColor: "#3a414c",
              color: "#A0A7B4",
              borderRadius: 0,
              marginBottom: 10,
            }}
          >
            <MapIcon style={{ fontSize: "16px" }} />
          </IconButton>
        </Tooltip>
        <Logout />
      </Box>
      <Box sx={{ position: "fixed", bottom: -20, right: 20 }}>
        <ToggleButton handleChange={(e) => setAdvancedMap(e.target.checked)} checked={advancedMap} />
      </Box>
      <RFLegend />
    </>
  );
};

export default PinMap;
